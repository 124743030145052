<template>
  <v-app>
    <v-main class="">
      <div class="" >
          <v-container
              class="white mt-5 pl-6 d-flex justify-space-between align-center"
          >
            <h1 class="primetime text-center"> Tracking Vehicle Details #{{ vin }}</h1>
            <!--          <v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
          </v-container>
          <v-container class="white mt-1 pt-5 px-5" v-if="!loading && trackingData">
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> VIN </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.vin }}
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col
                      cols="12"
                      sm="6"
                      class="pa-3 font-weight-bold"
                  >
                    Lot Number
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      class="pa-3 second-column"
                  >
                    {{ trackingData.lot_number }}
                  </v-col>
                </v-row>
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Customer Name-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.customer_name }}-->
<!--                  </v-col>-->
<!--                </v-row>-->

<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Company Name-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.company_name }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> VCR </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.vcr }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> HAT </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.hat_number }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Buyer No-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.buyer_id }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Keys </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
              <span v-if="trackingData.keys_name == 'No'" style="color: red">{{
                  trackingData.keys_name
                }}</span>
                    <span v-else>{{ trackingData.keys_name }}</span>
                  </v-col>
                </v-row>

                <v-row
                    no-gutters
                    align="center"
                    class="table-wrapper"
                    v-if="trackingData.status == 7"
                >
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                    Handed Over Date
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.handed_over_date }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4">

                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Year </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.year }}
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold"> Make </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.make }}
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                    Model
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.model }}
                  </v-col>
                </v-row>

<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Key Note-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.key_note }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
              </v-col>

              <v-col cols="12" sm="12" md="4">
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col-->
<!--                      cols="12"-->
<!--                      sm="6"-->
<!--                      class="pa-3 font-weight-bold"-->
<!--                      style="color: red"-->
<!--                  >-->
<!--                    Storage Amount-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.storage_amount }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Check No-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.check_number }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Value-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.value }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Add. CHGS-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.additional_charges }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                    Color
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.color }}
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                    Location
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.location_name }}
                  </v-col>
                </v-row>

<!--                <v-row no-gutters align="center" class="table-wrapper">-->
<!--                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">-->
<!--                    Weight-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" sm="6" class="pa-3 second-column">-->
<!--                    {{ trackingData.weight }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row no-gutters align="center" class="table-wrapper">
                  <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                    Status
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-3 second-column">
                    {{ trackingData.status_name }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="!loading && !trackingData">
            <h4>No Record Found</h4>
          </v-container>
          <v-container v-if="loading">
            <h4>Loading..</h4>
          </v-container>
        <div class="" v-if="trackingData && trackingData.photos">
          <v-container
              class="white mt-5 pl-6 d-flex justify-space-between flex-column flex-sm-row"
          >
            <h3 class="primetime text-uppercase">Vehicle Photos</h3>

          </v-container>

            <v-container class="white mt-1 pt-5 px-10">
              <v-row>
                <div class="images" v-viewer>
                  <img v-for="{thumbnail, url } in trackingData.photos" class="col-2 img-border lighten-2" :key="thumbnail" :src="url">
                </div>
                <!-- component -->
                <viewer :images="trackingData.photos">
                  <img v-for="{thumbnail} in images" :key="thumbnail" :src="thumbnail">
                </viewer>

              </v-row>
          </v-container>
        </div>

      </div>


    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

import Vue from 'vue'
Vue.use(VueViewer)
export default {

  name: "Tracking",
  data: () => ({
    baseDomain : process.env.VUE_APP_API_ENDPOINT,
    vin: null,
    trackingData : {},
    loading : false,
  }),
  computed: {
  },
  methods: {
    getData () {
      this.loading = true;
      const baseDomain = this.baseDomain;
      const vinNumber = this.vin;
      axios.get(`${baseDomain}/api/v1/tracking-vehicle?search=${vinNumber}`)
          .then(res => {
            this.trackingData = res.data.data;
            this.loading = false;
          })
          .catch(err => {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          });
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.vin) {
      this.vin = this.$route.query.vin;
      this.getData();
    }
  }
};
</script>
